import React from 'react';
import {
  Added,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from 'docComponents';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="November 2019"
      subnav="release-notes">
      <div id="November2019" className={styles.release}>
        <Paragraph className="uni-margin--one--vert">
          We've formally placed Version 3 into maintenance. What does that mean?
          Big things—Version 4 is in the works and is coming soon! Releases will
          be light until launch, but stuff's still happening, so read below.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />

            <div className={styles.changesList}>
              <Added>
                Pink to identity and tagging colors because a lot of teams
                support Breast Cancer Awareness Month.
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
